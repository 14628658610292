<template>
  <div class="list">
    <div class="tip">
      <p>温馨提示：请在投保前阅读并确认以下内容</p>
    </div>
    <div class="msg">
      <p>【承保公司】</p>
      <p>
        1、本保险产品由乐荐保保险代理有限公司销售，中国人民财产保险股份有限公司承保，通过互联网平台面向北京地区销售。
      </p>
      <p>【适用条款】</p>
      <p>
        2、适用条款：《中国人民财产保险股份有限公司监护人责任保险条款》人保（备案）[2009]N336号）、《中国人民财产保险股份有限公司监护人责任保险附加被监护人疫苗损害补偿保险条款》。
      </p>
      <p>【保单形式】</p>
      <p>
        3、保单形式：网上投保为您提供电子保单，根据《中华人民共和国合同法》第十一条规定，数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力。下载地址：http://www.
        picc.com.cn/ 首页左侧“服务中心”-“保单查询”。
        如您需要纸质保单及发票，请拨打人保财险公司客服电话010-95518进行申请，配送方式为快递到付。
      </p>
      <p>【偿付能力】</p>
      <p>
        4、本公司最近季度的综合偿付能力充足率为299.3%，偿付能力充足率和风险综合评级均达到监管要求。
      </p>
      <p>【产品介绍】</p>
      <p>
        5、受种者年龄需为0-13周岁，仅限在北京范围内接种的第一类疫苗、第二类疫苗、首针乙肝及卡介苗。
      </p>
      <p>
        6、在保险期限内，每位被保险人限购一份，多投无效，仅按单份保额计算赔偿金。
      </p>
      <p>7、本产品的最早生效日期为缴费成功后的次日零时。</p>
      <p>
        8、保障说明：在保险期间内，被监护人在接种单位接种第一类疫苗或第二类疫苗后产生损害，造成死亡、残疾或支出医疗费用，经法定的调查诊断及鉴定程序认定为预防接种异常反应的或预防接种不排除病例（包括疑似异常反应）的，保险人按照本保险合同约定负责赔偿。
      </p>
      <p>9、首针乙肝及卡介苗出生30天以内投保的，可保障异常反应保险责任。</p>
      <p>
        10、异常反应，是指合格的疫苗在实施规范接种过程中或者实施规范接种后造成被监护人机体组织器官、功能损害，相关各方均无过错的药品不良反应。
      </p>
      <p>
        11、发生被监护人残疾的，保险人按照国家现行有效的《医疗事故分级标准》确定残疾等级与本保险条款所附的《残疾赔偿比例表》对应的比例，乘以所对应保险责任的残疾赔偿限额赔偿：一至十级对应给付比例分别为保额的100%、90%、80%、70%、60%、50%、40%、30%、20%、10%。保险合同中其他条款与本特别约定存在不一致的，以本特别约定为准。
      </p>
      <p>
        12、被监护人因疫苗损害事故在二级及以上或保险人认可的其他医疗机构就医治疗的，保险人扣除社会保险、公费医疗及其他商业保险已经赔偿或给付的部分，再扣除保险单载明的免赔额或者依据免赔率计算的免赔额后，在对应保险责任的医疗费用赔偿限额以内据实赔偿。医疗费用赔偿范围及标准依据接种地人民政府颁布的基本医疗保险的报销标准。每次事故医疗费用免赔额：500元。
      </p>
      <p>
        13、本产品约定的住院津贴及住院伙食补助赔偿标准视方案而定，分别为：40元/天（呵护保障）、80元/天（安心保障）、160元/天（无忧保障）。
      </p>
      <p>
        14、本产品约定的误工费赔偿标准为：实际误工天数（天）×250元/天×1人，补偿最长不超过180天。
      </p>
      <p>
        15、本产品约定的交通费赔偿标准为：实际发生的费用（不包括飞机头等舱和火车软卧）（限2人）。
      </p>
      <p>
        16、本产品约定的残疾用具费赔偿标准为：按国产普通适用型器具配置，需更换的按4次计算。
      </p>
      <p>
        17、本产品约定的住院津贴及住院伙食补助，误工费，交通费，残疾用具费均在医疗费用向下赔付，共用医疗费用保额。
      </p>
      <p>
        18、在本保险期限内，由被保险人的监护对象（被监护人）造成第三者人身伤亡或财产损失，依法应由被保险人承担赔偿责任时，保险人根据本保险合同的规定负责赔偿。
      </p>
      <p>19、下列原因造成的损失、费用和责任，保险人不负责赔偿：</p>
      <p>
        （1）投保人、被保险人及其代表的故意行为、犯罪行为；<br />
        （2）疫苗质量不合格；接种单位违反预防接种工作规范、免疫程序、疫苗使用指导原则、接种方案；<br />
        （3）被监护人有疫苗说明书规定的接种禁忌，在接种前被监护人或者其监护人未如实提供被监护人的健康状况和接种禁忌等情况，接种后被监护人原有疾病急性复发或者病情加重；<br />
        （4）因心理因素发生的个体或者群体的心因性反应。
      </p>
      <p>20、下列损失、费用和责任，保险人也不负责赔偿：</p>
      <p>
        （1）罚款、罚金或惩罚性赔偿；<br />
        （2）精神损害赔偿；<br />
        （3）财产损失；<br />
        （4）间接损失；<br />
        （5）社会保险、公费医疗或其他商业保险已经支付的医疗费用；<br />
        （6）保险单载明的免赔额或者按照保险单载明的免赔率计算的免赔额。
      </p>
      <p>21、下列原因造成的损失、费用和责任，保险人不负责赔偿：</p>
      <p>
        （1）被保险人或与其共同居住的成年家庭成员对被监护人的教唆；<br />
        （2）战争、敌对行为、军事行动、武装冲突、恐怖活动、罢工、骚乱、暴动。<br />
        （3）被保险人及其家庭成员的人身伤亡和财产损失；<br />
        （4）被监护人系精神病人所致的赔偿责任；<br />
        （5）其他不属于本保险责任范围内的一切损失、费用和责任，保险人不负责
      </p>
      <p>
        22、如需办理保全问题或保险咨询，请您拨打人保财险官方客服电话010-95518；退保需提供投被保险人有效证件及退保说明（退保原因+保单号+投被保险人签字）。
      </p>
      <p>
        23、本保单不设犹豫期，保单生效后投保人要求解除本合同，保险人根据以下计算公式退还未满期保费：未满期保费=保费×（1-m/n），其中，m为已生效天数，n为保险期间的天数，经过日期不足一日的按一日计算。
      </p>
      <p>
        24、本产品所有页面的文字描述均为展示作用，具体保障信息以购买成功后生成的电子保单为准，保险公司保留在法律规定范围内的解释权利。
      </p>
      <p>
        25、请投保前认真阅读条款，了解保险责任及责任免除，尤其是对其中免除保险人责任的条款（包括但不限于责任免除、投保人被保险人义务、保险金申请与给付等），请充分理解并接受上述内容同意以此作为订立保险合同的依据。
      </p>
      <p>
        26、相关授权：如果您投保此保险，视为您及被保险人同意授权本公司出于提供保险服务的需要：可向与本公司具有必要合作关系的机构提供您或被保险人的信息（包括但不限于投保、承保、理赔、账户使用信息等）；本公司可通过知悉您或被保险人信息的机构查询与您或被保险人有关的全部信息；本公司及与本公司具有必要合作关系的机构均可对上述信息进行合理使用。
      </p>
      <p>
        27、信息安全：我们严格遵守现行的关于个人信息，数据及隐私保护的法律法规，采取充分的技术手段和制度管理，保护您提供给我们的个人信息、数据和隐私不受到非法的泄漏或披露给未获授权的第三方。
      </p>
    </div>
    <div class="btn" v-if="$route.query.channel">
      <p @click="exemptionClause">我已阅读并同意以上内容</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    //跳转责任免除条款
    exemptionClause() {
      this.$router.push({
        path: "/exemptionClause",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  width: 100%;
  height: 44px;
  background: #fff7f4;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color;
    line-height: 21px;
  }
}
.msg {
  margin-top: 16px;
  padding: 0 20px;
  padding-bottom: 100px;
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: justify;
  }
}
.btn {
  width: 100%;
  height: 90px;
  background: #fff;
  position: fixed;
  z-index: 1000;
  left: 0;
  bottom: 0;
  p {
    width: 234px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: bold;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
  }
}
</style>